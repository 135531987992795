// src/components/Footer.js
import React from 'react';

export default function Footer() {
  return (
    <footer className="relative bg-black bg-opacity-50 py-8 mt-16">
      <div className="container mx-auto text-center px-4">
        <p className="text-gray-500">&copy; 2024 FologanGames. All rights reserved.</p>
      </div>
    </footer>
  );
}
