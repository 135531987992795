import React, { useState, useEffect } from 'react';

export const gameData = {
  title: 'Acomoda Bolas',
  description: 'Un juego divertido donde acomodas bolas.',
  category: 'Puzzle',
  image: '/assets/images/acomoda-bolas.jpg',
};

const colors = ['purple', 'red', 'yellow', 'green', 'blue'];
const maxBallsPerContainer = 5; // Ajustado al número mencionado en el código original

function AcomodaBolas() {
  const [containers, setContainers] = useState([]);
  const [selectedContainerIndex, setSelectedContainerIndex] = useState(null);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [score, setScore] = useState(50);

  // Inicializar el juego al montar el componente
  useEffect(() => {
    resetGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startGame = () => {
    setIsGameRunning(true);
    setScore(50);
    resetGame();
  };

  const resetGame = () => {
    setSelectedContainerIndex(null);
    const initialContainers = Array.from({ length: 6 }, (_, i) => ({
      id: i,
      balls: [],
      locked: false,
    }));

    let allBalls = [];
    colors.forEach((color) => {
      for (let i = 0; i < 5; i++) {
        allBalls.push(color);
      }
    });

    // Mezclar las bolas
    allBalls = allBalls.sort(() => Math.random() - 0.5);

    // Distribuir las bolas en los contenedores
    allBalls.forEach((ball, index) => {
      initialContainers[index % initialContainers.length].balls.push(ball);
    });

    setContainers(initialContainers);
    setIsGameRunning(false);
  };

  const handleContainerClick = (index) => {
    if (!isGameRunning) return;

    const clickedContainer = containers[index];

    if (clickedContainer.locked) return;

    if (selectedContainerIndex === null) {
      if (clickedContainer.balls.length === 0) return;
      setSelectedContainerIndex(index);
    } else {
      if (selectedContainerIndex === index) {
        setSelectedContainerIndex(null);
        return;
      }
      moveBall(selectedContainerIndex, index);
      setSelectedContainerIndex(null);
    }
  };

  const moveBall = (fromIndex, toIndex) => {
    const fromContainer = containers[fromIndex];
    const toContainer = containers[toIndex];

    if (fromContainer.balls.length === 0) return;
    if (toContainer.balls.length >= maxBallsPerContainer) return;

    const ballToMove = fromContainer.balls[fromContainer.balls.length - 1];

    if (
      toContainer.balls.length === 0 ||
      toContainer.balls[toContainer.balls.length - 1] === ballToMove
    ) {
      const updatedContainers = containers.map((container, index) => {
        if (index === fromIndex) {
          return {
            ...container,
            balls: container.balls.slice(0, -1),
          };
        }
        if (index === toIndex) {
          const updatedBalls = [...container.balls, ballToMove];
          const isLocked =
            updatedBalls.length === maxBallsPerContainer &&
            updatedBalls.every((ball) => ball === ballToMove);
          return {
            ...container,
            balls: updatedBalls,
            locked: isLocked,
          };
        }
        return container;
      });

      setContainers(updatedContainers);
      setScore((prev) => prev - 1);
      checkWinCondition(updatedContainers);
    }
  };

  const checkWinCondition = (currentContainers) => {
    if (
      currentContainers.every(
        (container) => container.locked || container.balls.length === 0
      )
    ) {
      setIsGameRunning(false);
      setTimeout(() => {
        alert(`¡Felicidades, has ganado! Tu puntuación final es: ${score}`);
      }, 100);
    }
  };

  return (
    <div className="flex flex-col items-center bg-gray-800 min-h-screen p-4 text-white">
      <h1 className="text-3xl font-bold mb-4">{gameData.title}</h1>
      <div className="mb-6 text-center">{gameData.description}</div>
      <h2 className="text-xl font-semibold mb-2">Instrucciones</h2>
      <div className="mb-6 text-center">
        Sólo puedes mover las bolas a un contenedor con la primera bola del mismo
        color.
      </div>
      <div className="grid grid-cols-6 gap-4">
        {containers.map((container, index) => (
          <div
            key={container.id}
            onClick={() => handleContainerClick(index)}
            className={`w-20 h-48 flex flex-col-reverse items-center p-2 border-2 ${
              container.locked
                ? 'border-green-500'
                : selectedContainerIndex === index
                ? 'border-yellow-500'
                : 'border-white'
            } cursor-pointer bg-blue-900 rounded`}
          >
            {container.balls.map((ball, ballIndex) => (
              <div
                key={ballIndex}
                className={`w-8 h-8 mb-1 rounded-full bg-${ball}-500`}
              ></div>
            ))}
          </div>
        ))}
      </div>
      <div className="flex space-x-4 mt-6">
        <button
          onClick={startGame}
          className="px-4 py-2 bg-green-500 hover:bg-green-600 rounded"
        >
          START
        </button>
        <button
          onClick={resetGame}
          className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded"
        >
          RESET
        </button>
      </div>
      <p className="mt-4 text-lg">Puntuación: {score}</p>
    </div>
  );
}

export default AcomodaBolas;
