import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function WelcomeSection({ getRandomGame }) {
  const navigate = useNavigate();

  const handleRandomGame = () => {
    const randomGame = getRandomGame();
    navigate(`/${randomGame.id}`);
  };

  return (
    <section className="w-1/2 min-w-72 m-auto flex flex-col justify-items-center p-9 bg-gradient-to-b from-purple-700 via-purple-950 to-gray-900 text-center mb-9 rounded-2xl">
      <motion.h2 
        className="text-5xl font-bold mb-4 text-purple-100"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Welcome 2 play 🤖
      </motion.h2>
      <motion.p 
        className="text-xl text-gray-300 mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        Sin anuncios, juegos totalmente gratis sin descargar nada y de código abierto!
      </motion.p>
      <div className="flex justify-center space-x-4">
        <motion.button
          className="px-6 py-3 bg-purple-800 text-white font-semibold rounded-xl hover:bg-purple-700 transition-colors duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRandomGame}
        >
          🎲 Game
        </motion.button>
        <motion.button
          className="px-6 py-3 bg-gray-950 font-semibold text-white rounded-xl hover:bg-purple-700 transition-colors duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/library')}
        >
          📚 Biblioteca
        </motion.button>
      </div>
    </section>
  );
}