// src/components/Header.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaYoutube, FaBars, FaTimes } from 'react-icons/fa';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="h-16 relative bg-black bg-opacity-50 rounded-lg z-50 px-6">
      <nav className="container mx-auto flex items-center justify-between">
        <a href="/" className="text-4xl font-black text-purple-400 mt-2">Fologan Games</a>
        <div className="hidden md:flex items-center justify-center space-x-6">
          <a href="https://www.fologan.com" className="text-white hover:text-purple-400 transition-colors duration-300 self-center">Blog</a>
          <a href="https://www.youtube.com/Fologan" className="text-white hover:text-purple-400 transition-colors duration-300">
            <FaYoutube className="inline-block mr-1" />
            YouTube
          </a>
        </div>
        <button className="md:hidden text-white z-50 mt-3" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </nav>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="absolute top-full left-0 right-0 bg-gray-900 py-4 z-40"
          >
            <div className="container mx-auto px-4 flex flex-col items-center space-y-4">
              <a href="https://www.fologan.com" className="text-white hover:text-purple-400 transition-colors duration-300">Blog</a>
              <a href="https://www.youtube.com/Fologan" className="text-white hover:text-purple-400 transition-colors duration-300">
                <FaYoutube className="inline-block mr-1" />
                YouTube
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
