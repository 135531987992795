// src/pages/Home.js
import React from 'react';
import WelcomeSection from '../components/WelcomeSection';
import FeaturedGames from '../components/FeaturedGames';
import GameCategories from '../components/GameCategories';

export default function Home({ games }) {
  const categories = [...new Set(games.map((game) => game.category))];

  const getRandomGame = () => {
    const randomIndex = Math.floor(Math.random() * games.length);
    return games[randomIndex];
  };

  return (
    <>
      <WelcomeSection getRandomGame={getRandomGame} />
      <FeaturedGames games={games} />
      <GameCategories categories={categories} />
    </>
  );
}
