// src/components/JuegoReact/JuegoReact.jsx

import React, { useState } from 'react';

const JuegoReact = () => {
  const [resultado, setResultado] = useState('');
  const [imagen, setImagen] = useState('');

  // Definir las posibles frases para pronombres
  const pronombres = [
    "Después de verlo, me parece que",
    "A primera vista se ve que",
    "Después de revisar su fuerza vi que",
    "Ay mamá qué tenemos aquí, este wey",
    "Santa cachucha pero si"
  ];

  const olores = [
    { texto: "es super débil que le pesa la ropa", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/PesaRopa.webp` },
    { texto: "su debilidad es tanta que va al baño para quitarse un peso de encima", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/Encima.webp` },
    { texto: "es flacucho y débil que sus pesas son peluches", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/OsosPeluche.webp` },
    { texto: "es tan débil que cuando carga pesas se le salen los pedos", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/PesasPedos.webp` },
    { texto: "es una persona común y corriente, más corriente que común", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/Corriente.webp` },
    { texto: "es tan fuerte que carga 3 garrafones sin sudar", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/Garrafones.webp` },
    { texto: "es tan fuerte que carga un auto lleno de gordos", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/AutoObesos.webp` },
    { texto: "es tan poderoso que Thanos le tiene miedo", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/Thanos.jpg` },
    { texto: "es tan poderoso que Goku ultra-instinto le tiene miedo", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/TengoMiedo.jpg` },
    { texto: "es el más super poderosísimo del universo", imagen: `${process.env.PUBLIC_URL}/assets/QueFuerte/Universo.webp` }
  ];

  const handleClick = () => {
    const pronombre = pronombres[Math.floor(Math.random() * pronombres.length)];
    const olorSeleccionado = olores[Math.floor(Math.random() * olores.length)];
    setResultado(`${pronombre} ${olorSeleccionado.texto}`);
    setImagen(olorSeleccionado.imagen);

    // Uso de la API de Speech Synthesis
    const msg = new SpeechSynthesisUtterance(`${pronombre} ${olorSeleccionado.texto}`);
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="mx-auto  p-4 w-4/5 max-w-3xl border-3 border-pink-500 shadow-lg rounded-lg flex flex-col items-center bg-gray-900 text-white">
      <h1 className="text-2xl mb-4">Da click al botón ⬇️</h1>
      <div className="mb-4">
        <button
          onClick={handleClick}
          className="bg-gray-800 border-2 border-pink-500 shadow-lg  px-4 py-2 rounded-lg hover:bg-gray-700 transition transform hover:scale-105 animate-pulse"
        >
          ¿Qué tan fuerte soy?
        </button>
      </div>
      <div className="mt-4 w-full aspect-square relative overflow-hidden">
        {imagen && (
          <img 
            src={imagen} 
            alt="Resultado" 
            className="absolute top-0 left-0 w-full h-full object-cover p-1 rounded-lg"
          />
        )}
      </div>
      <p className="bg-gray-800 border-2 border-pink-500 rounded-lg p-4 w-full min-h-[150px] text-pink-500 text-center mt-6">
        {resultado}
      </p>
    </div>
  );
};

export default JuegoReact;

// Metadatos del juego
export const gameData = {
  id: 'juego-fuerza',
  title: 'Juego de Fuerza',
  description: 'Un juego interactivo para evaluar la fuerza percibida.',
  category: 'Entretenimiento',
  image: `${process.env.PUBLIC_URL}/assets/QueFuerte/PesaRopa.webp` // Imagen representativa del juego
};
