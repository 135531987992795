// src/components/JuegoReact/JuegoReact.jsx

import React, { useState } from 'react';

const JuegoReact = () => {
  const [resultado, setResultado] = useState('');
  const [imagen, setImagen] = useState('');

  // Pronombres para "¿A qué huelo?"
  const pronombresYo = [
    "Después de olerte, me parece que hueles a",
    "Al parecer tu olor es el de",
    "Hueles a",
    "Hazte para allá que hueles a",
    "Santa cachucha tu olor es el de"
  ];

  // Pronombres para "¿A qué huele?"
  const pronombresOtro = [
    "Después de olerlo, me parece que huele a",
    "Al parecer su olor es el de",
    "Huele a",
    "No me lo creo él huele a",
    "Santa cachucha su olor es el de"
  ];

  // Array de olores con textos e imágenes externas
  const olores = [
    { texto: "pedo apestoso, peor que oler basura", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEinr47hsnDhFss0S0ilT1xecAlkFp85_N-OEYDC04mk20lZ526X56Hc41v1ucjEByhbjEUSUBGxKiPqR6iTPC5W1EIjw2r2eKU4JLE_iQJw-MpzkgDPB0K9Bx5GlJA3ibY8XyjEyUIxHS8e40NcyB5qAw5BGMzrs6nKwOPoXPj43fICE8uLY-oztyb9yMhB/s480/1.WEBP" },
    { texto: "pedito MORTAL, que huelen todos los vecinos y les duele la cabeza", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgm05uXVbXH555uU-bG1912dddzAFw4HT_qC2sx8nt4vRcjutmT4quZwz3sH-DfiADJn3c3kLi4ik_5DZDl5po9a9fQbcG_lQ-fHojEFU-mKMDvHWPCy9NI7pFKt96CGGGO2xRZSlqTKF3ddZFG6hkmhxnu5twqn7Qy2GZEre_lqJe4aAMzkpcJBMp8Ii-E/s480/2.WEBP" },
    { texto: "pedo con caldo verde que deja los calzones manchados de por vida", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiPisERX71anqPXDhNjPkw242UjDLlTbt4kUQtojULDWt3qPU0X-ACspjjlsTepRDiCXjOfQalR98Yq7szFr8UVHmAokgkkeP9di00K1RTU7w7kNu1VR-kBmAP_Ghr8iRsZ-Hg4HMM8wXOyiks3OQT7y6YoY18oKy4j1R_IaVqmppQPXqcBuigzUl3BDFD8/s480/3.WEBP" },
    { texto: "pedo con sangre infectada que puede causar otra pandemia de pedos", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjdnYCIWyxfwuBozR2UHcfwITrdL7hYdMAqDjYaKxoPmgpo23YllQUOJZE4TKRclYupAIyuAfGiw3pLarFfyThJwiTX0tRFMzro-UU5-bRTk-oDOM2GU4LH4Id6ndAX60TPvV7uUTp_gP25BtrGbjN74L0jvcKCGsHL-hXPBFbRW60kxDiL-JRE7KEDodtw/s480/4.WEBP" },
    { texto: "pedo atómico verde, que si lo hueles... pierdes la vida", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEilH-eR7qCWAgv_iOSXgj9bYz58HtarZ_0dJNQrm4gygLjJEGCGvXvrfIanU6Rryz96CrCsKQJ_NiXerM7oaxM-Uwx2jcdrlB8IOd2FgTQRxFncmqBrmBQxkPQ6xgzi_tjzLKliCgqYoMEksUCUjkroA2jG08Kjiw0BNjXCGk3gMZpNb1_oiTrGfgh1TnVL/s480/5.WEBP" },
    { texto: "el mejor perfume carísimo", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiNSGoi1g57Gl2zMQznxX314SE6pYz-OufV-U1idnne_ueAIXTFZIR4tT2VfnRkeQ8Ap8VsXOUE-O0moMNDAwdQ49OKAtJeXxc7jYe72h5iggJfXmHw93EiFx4KXPNf0KZvMLhFu93quJXJ3dIZKdrhVInONHJp3DkcZUnPaFndAfDQ5C57wI7Kb_3jGj48/s480/6.WEBP" },
    { texto: "un super sayayín fase Dios", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjyXQFuUTzHeA4pfzMpwmrT9C8mpOTslZujIdvg7YF9YTzhO9gVqKz9i2fFDDJR5rLf2KgxglDRxck0YdZYrRZWyobSfMxbRX8gmLgiz84LqN7z-oOsf7MV_Y5i_4vq81U5ZNWzqP8DDCIzEqxVdhLm4PwPCJ9QO00M-GsyhDp2MZpMW_l_dXQsbzVN5K40/s480/7.WEBP" },
    { texto: "las flores hermosas más bellas del mundo", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjNqBXohLjxw-4O5lfbICl2UxjQWrlRghWMpx1w8HhUTcDdEhRVLN455bW5Kg2dhib0Nhy_2gquYto3bewRsAti1_0G0cfG6MMyGRFlMcOSDye-WwW6VBn5dVKq-4rak8Tbr2kr47n1xCv3npxmgcAQG9hWzp6VOty9kx7p3hsvTh7jp9dxS2jK5w0W6H8P/s480/8.WEBP" },
    { texto: "una persona que se baña muy pero muy bien", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiaIOMI24THmeB23Y4UIO6-2lDWBD1eph-061cVFW-zGjq_qsyYEqCOxlXP3yx3SRZDF_EycoPORFn12uCPavWUtCnFL-c-VsKCWmMBSlielQUnrTgsQGvsI5JRV-1ud9BtcnW-JcnsQOMR5swwjO6diuN-hRtLyNb9faMs4QKYeA96fiNwZ6Wj7XRJLYtu/s480/9.WEBP" },
    { texto: "la persona más limpia del universo. Un Dios de la limpieza", imagen: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi0bmvMtpeM5FWlNh_8hoob_6zsRMYKhwHt0aDnXhSVleWLt1815pcViu3hg4bf59-ldgmAXAH2u4DXfwCdJOdtVik584kuY9_VxE8I-nPIzb_B-VFK2f_i-H_tWFEy_D1Ultboyy-y9u3OHLxzhzntFo5IJaeucD1HTl1769IeasfUNF2FvVTu6hIhDcBt/s480/10.WEBP" }
  ];

  // Función para manejar clic en los botones
  const handleClick = (tipo) => {
    let pronombre = '';
    if (tipo === 'yo') {
      pronombre = pronombresYo[Math.floor(Math.random() * pronombresYo.length)];
    } else if (tipo === 'otro') {
      pronombre = pronombresOtro[Math.floor(Math.random() * pronombresOtro.length)];
    }

    const olorSeleccionado = olores[Math.floor(Math.random() * olores.length)];
    setResultado(`${pronombre} ${olorSeleccionado.texto}`);
    setImagen(olorSeleccionado.imagen);

    // Uso de la API de Speech Synthesis
    const msg = new SpeechSynthesisUtterance(`${pronombre} ${olorSeleccionado.texto}`);
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="mx-auto mt-8 p-4 w-4/5 h-[720px] border-3 border-pink-500 shadow-lg rounded-lg flex flex-col items-center bg-gray-900 text-white">
      <h1 className="text-2xl mb-4">Da click a un botón</h1>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => handleClick('yo')}
          className="bg-gray-800 border-2 border-pink-500 shadow-lg text-lg px-4 py-2 rounded-lg hover:bg-gray-700 transition transform hover:scale-105"
        >
          ¿A qué huelo?
        </button>
        <button
          onClick={() => handleClick('otro')}
          className="bg-gray-800 border-2 border-pink-500 shadow-lg text-lg px-4 py-2 rounded-lg hover:bg-gray-700 transition transform hover:scale-105"
        >
          ¿A qué huele?
        </button>
      </div>
      <div className="mt-4 flex justify-center items-center overflow-hidden">
        {imagen && (
          <img
            src={imagen}
            alt="Resultado"
            className="max-w-full h-auto p-1 rounded-lg"
          />
        )}
      </div>
      <p className="mt-6 bg-gray-800 border-2 border-pink-500 rounded-lg p-4 w-7/10 min-h-[150px] text-pink-500 text-center">
        {resultado}
      </p>
    </div>
  );
};

export default JuegoReact;

// Metadatos del juego
export const gameData = {
  id: 'juego-olor',
  title: 'Juego de Olor',
  description: 'Un juego interactivo para descubrir a qué hueles tú o a otra persona.',
  category: 'Entretenimiento',
  image: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEinr47hsnDhFss0S0ilT1xecAlkFp85_N-OEYDC04mk20lZ526X56Hc41v1ucjEByhbjEUSUBGxKiPqR6iTPC5W1EIjw2r2eKU4JLE_iQJw-MpzkgDPB0K9Bx5GlJA3ibY8XyjEyUIxHS8e40NcyB5qAw5BGMzrs6nKwOPoXPj43fICE8uLY-oztyb9yMhB/s480/1.WEBP" // Imagen representativa del juego
};
