import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaYoutube, FaBars, FaTimes } from 'react-icons/fa';

function AcomodaBolasGame() {
  // Estado del juego
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 100) + 1
  );
  const [intentos, setIntentos] = useState(6);
  const [numBase, setNumBase] = useState(0);
  const [numPico, setNumPico] = useState(100);
  const [incognita, setIncognita] = useState('?');
  const [inputValue, setInputValue] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [juegoTerminado, setJuegoTerminado] = useState(false);
  const [mostrarReiniciar, setMostrarReiniciar] = useState(false);

  const guessNumber = () => {
    const e = Number(inputValue);
    if (!e || e < 1 || e > 100) {
      setMensaje('Por favor, ingresa un número válido entre 1 y 100.');
      return;
    }

    if (e === randomNumber) {
      setMensaje(
        `¡Felicidades! ¡Adivinaste el número! <div class="text-blue-500 font-bold">Intentos restantes: ${intentos}</div>`
      );
      setIncognita(e);
      setJuegoTerminado(true);
      setMostrarReiniciar(true);
    } else {
      const nuevosIntentos = intentos - 1;
      setIntentos(nuevosIntentos);

      if (nuevosIntentos === 0) {
        setMensaje(`<p class="animate-pulse">¡Game Over! El número era:</p> <p class="text-5xl font-bold text-purple-300"> ${randomNumber}</p>`);
        setJuegoTerminado(true);
        setMostrarReiniciar(true);
      } else {
        if (e < randomNumber) {
          setMensaje(
            `➕ MÁS <div class="text-blue-500 font-bold">Te quedan: ${nuevosIntentos} intentos.</div>`
          );
          setNumBase(e);
        } else {
          setMensaje(
            `➖ MENOS <div class="text-blue-500 font-bold">Te quedan: ${nuevosIntentos} intentos.</div>`
          );
          setNumPico(e);
        }
      }
    }
    setInputValue('');
  };

  const restartGame = () => {
    setRandomNumber(Math.floor(Math.random() * 100) + 1);
    setIntentos(7);
    setNumBase(0);
    setNumPico(100);
    setIncognita('?');
    setInputValue('');
    setMensaje('');
    setJuegoTerminado(false);
    setMostrarReiniciar(false);
  };

  return (
    <div className="p-6 flex items-center justify-center">
      {/* Código del juego */}
      <motion.div
        className="bg-gray-700 p-6 rounded-lg shadow-lg w-full max-w-md"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div id="container" className="flex flex-col items-center">
          <span className="text-center">
            <p className="text-lg font-medium mb-3">
              Ingresa tu suposición, dale al botón de adivinar y te aparecerá en pantalla si te falta o sobra. La gráfica al final te ayudará.
            </p>
            <input
              type="number"
              id="guessInput"
              placeholder="Ingresa tu suposición"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={juegoTerminado}
              className="w-full p-2 bg-zinc-900 border border-gray-300 rounded mb-4 text-center"
            />
            <div className="flex space-x-4 mb-4">
              {!juegoTerminado && (
                <button
                  id="adivinar"
                  onClick={guessNumber}
                  className="px-4 py-2 bg-purple-700 text-white rounded hover:saturate-150 transition duration-300 ease-in-out m-auto"
                >
                  Adivinar
                </button>
              )}
              {mostrarReiniciar && (
                <button
                  id="restartButton"
                  onClick={restartGame}
                  className="m-auto px-4 py-2 bg-green-700 text-white rounded hover:saturate-150 transition duration-300"
                >
                  Reiniciar
                </button>
              )}
            </div>
            <p
              id="result"
              className="text-2xl text-purple-400 font-bold mb-4"
              dangerouslySetInnerHTML={{ __html: mensaje }}
            ></p>
            <div
              id="grafica"
              className="grafica w-full h-12 bg-gradient-to-r from-blue-500 to-red-500 rounded-lg flex items-center justify-between px-4 font-bold text-white"
            >
              <p style={{ flexGrow: 1, textAlign: 'left' }}>{numBase}</p>
              <p style={{ flexGrow: 1, textAlign: 'center', fontSize: '2rem' }}>
                {incognita}
              </p>
              <p style={{ flexGrow: 1, textAlign: 'right' }}>{numPico}</p>
            </div>
          </span>
        </div>
      </motion.div>
    </div>
  );
}

export const gameData = {
  title: 'Acomoda Bolas',
  description: 'Un juego divertido donde acomodas bolas.',
  category: 'Puzzle',
  image: '/assets/images/acomoda-bolas.jpg',
};

export default AcomodaBolasGame;
