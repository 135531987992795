// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import GamePage from './pages/GamePage';
import CategoryPage from './pages/CategoryPage';
import GameLibrary from './pages/GameLibrary';
import games from './games'; // Importamos la lista de juegos

export default function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home games={games} />} />
          <Route path="/library" element={<GameLibrary games={games} />} />
          {games.map((game) => (
            <Route
              key={game.id}
              path={`/${game.id}`}
              element={<GamePage game={game} />}
            />
          ))}
          <Route path="/category/:category" element={<CategoryPage games={games} />} />
        </Routes>
      </Layout>
    </Router>
  );
}
