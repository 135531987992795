import React from 'react';
import { useParams } from 'react-router-dom';
import FeaturedGames from '../components/FeaturedGames';

export default function CategoryPage({ games }) {
  const { category } = useParams();
  const filteredGames = games.filter(game => game.category === category);

  return (
    <div>
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-400">Juegos en la categoría: {category}</h1>
      <FeaturedGames games={filteredGames} />
    </div>
  );
}

