// src/components/FeaturedGames.js
import React from 'react';
import { Link } from 'react-router-dom';

export default function FeaturedGames({ games }) {
  if (games.length === 0) {
    return <p className="text-center text-xl text-gray-400">No hay juegos disponibles en esta categoría.</p>;
  }

  return (
    <section className="mb-12">
      <h2 className="text-3xl font-bold mb-8 text-center text-purple-400">Juegos Destacados</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {games.map((game) => (
          <div key={game.id} className="flex flex-col items-center">
            <Link to={`/${game.id}`} className="card relative z-10">
              <img src={game.image} alt={game.title} className="w-full h-full object-cover absolute inset-0 z-10 hover:opacity-10" />
              <div className="card-content z-20 relative">
                <h3>{game.title}</h3>
              </div>
            </Link>
            <span className="mt-4 text-purple-400">{game.category}</span>
          </div>
        ))}
      </div>
    </section>
  );
}
