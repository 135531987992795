// src/games/index.js
import gamesData from '../data/gamesData.json';

// Importamos todos los componentes de juegos
const gameModules = {
  AcomodaBolas: require('./AcomodaBolas').default,
  AdivinaNumeros: require('./AdivinaNumeros').default,
  QueFuerte: require('./QueFuerte').default,
  QueHuele: require('./QueHuele').default,
  // ... otros juegos
};

const games = gamesData.map((game) => ({
  ...game,
  component: gameModules[game.id],
}));

export default games;
