// src/pages/GamePage.js
import React from 'react';

export default function GamePage({ game }) {
  if (!game) {
    return <div>Juego no encontrado</div>;
  }

  const GameComponent = game.component;

  return (
    <div className="game-page">
      <h1 className="text-4xl font-bold mb-4 text-center">{game.title}</h1>
      <p className="text-center text-xl font-semibol">{game.description}</p>
      {/* Renderiza el componente del juego */}
      <div className="game-container">
        <GameComponent />
      </div>
    </div>
  );
}
