// src/components/Layout.js
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <div className="min-h-screen text-white font-futuristic bg-gray-900">
    <Header />
    <main className="container mx-auto mt-9 px-4">
      {children}
    </main>
    <Footer />
  </div>
);

export default Layout;
