// src/components/GameCategories.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaGamepad } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function GameCategories({ categories }) {
  return (
    <section className="mb-12 m-9">
      <h2 className="text-3xl font-bold mb-8 text-center text-purple-400">Categorías de Juegos</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 justify-items-stretch">
        {categories.map((category) => (
          <Link to={`/category/${category}`} key={category}>
            <motion.div
              className="bg-gray-950 rounded-lg p-4 text-center cursor-pointer hover:bg-purple-600 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
            >
              <FaGamepad className="text-4xl mb-2 mx-auto text-purple-400" />
              <p>{category}</p>
            </motion.div>
          </Link>
        ))}
      </div>
    </section>
  );
}
