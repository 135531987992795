import React from 'react';
import { Link } from 'react-router-dom';

export default function GameLibrary({ games }) {
  const categories = [...new Set(games.map(game => game.category))];

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-4xl font-bold mb-8 text-center text-purple-400">Biblioteca de Juegos</h1>
      {categories.map(category => (
        <div key={category} className="mb-8">
          <h2 className="text-3xl font-bold mb-4 text-purple-300">{category}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {games
              .filter(game => game.category === category)
              .map(game => (
                <Link to={`/${game.id}`} key={game.id} className="block">
                  <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <img src={game.image} alt={game.title} className="w-full h-60 object-cover" />
                    <div className="p-4">
                      <h3 className="text-xl font-semibold mb-2 text-purple-300">{game.title}</h3>
                      <p className="text-gray-400">{game.description}</p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}